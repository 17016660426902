import React from "react"
import LayoutDay from "../../../components/Chronology/LayoutDay"
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft"
import ContentRight from "../../../components/Chronology/Modules/ContentRight"
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable"
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu"
import ModDotList from "../../../components/Chronology/Modules/ModDotList"
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine"
import ModImage from "../../../components/Chronology/Modules/ModImage"
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu"
import ModText from "../../../components/Chronology/Modules/ModText"

export const frontmatter = {
  title: "Día 112",
  week: "Semana 16",
  day: "03",
  month: "Jul",
  monthNumber: "07",
  date: "2020-07-03",
  path: "/cronologia/semana-16#dia-03-jul/",
}

const Day112 = props => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en <strong>España</strong> 2.028 casos
          mediante pruebas PCR. En ese mismo periodo, 141 personas han requerido hospitalización
          (7,0% de los diagnósticos), de los que 13 han sido ingresos en la UCI, y se han producido
          21 fallecimientos.
        </ModText>
        <ModText>
          El Gobierno ha aprobado hoy un nuevo paquete de medidas en el marco del{" "}
          <strong>Acuerdo por la Reactivación Económica y el Empleo</strong>. Entre las medidas que
          incluye este RDL, está la creación de un fondo de <strong>10.000 millones</strong> para
          apoyar la solvencia de las empresas estratégicas afectadas por COVID-19.
        </ModText>
        <ModText>
          El Fondo de Apoyo a la Solvencia de Empresas Estratégicas está dirigido a sociedades que
          atraviesen severas dificultades de carácter temporal como consecuencia de la situación
          generada por el COVID-19 y que sean consideradas estratégicas por diversos motivos:
        </ModText>
        <ModDotList>Su impacto social y económico.</ModDotList>
        <ModDotList>
          Relevancia para la salud y la seguridad, las infraestructuras o las comunicaciones.
        </ModDotList>
        <ModDotList>Contribución al buen funcionamiento de los mercados.</ModDotList>
        <ModImage src="/images/svg/playa-turismo.svg" alt="turismo" />

        <ModText>
          Por otra parte, el Gobierno también ha aprobado tres medidas de apoyo al sector turístico
          español. Por un lado, se pone en marcha un sistema de financiación de{" "}
          <strong>proyectos para la digitalización y la innovación del sector turístico.</strong>
        </ModText>
        <ModText>
          Además, se ha aprobado la creación del instrumento denominado "
          <strong>Planes de Sostenibilidad Turística en Destinos</strong>". El objetivo es impulsar
          el desarrollo de los destinos turísticos ubicados en áreas rurales y de interior.
        </ModText>
        <ModText>
          Y en tercer lugar, se ha aprobado una <strong>moratoria hipotecaria</strong> para
          inmuebles afectos a actividad turística, a través del otorgamiento de un periodo de
          moratoria de hasta doce meses para las operaciones financieras de carácter hipotecario
          suscritas con entidades de crédito.
        </ModText>
      </ContentRight>
    </LayoutDay>
  )
}
export default Day112
